.m_primary {
  background-color: #b1401d;
}

.m_secondary {
  background-color: #346119;
}

.mato-primary {
  color: #b1401d;
}

.mato-secondary {
  color: #346119;
}

.mato-gray {
  color: #6c757d;
}
.mato-lightgray {
  color: #f8f9fa;
}

.card-title {
  color: #b1401d;
}

.btn-primary {
  background-color: #b1401d !important;
}

.login-image {
  width: 406px;
  -webkit-box-shadow: -1px 0px 5px -1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: -1px 0px 5px -1px rgba(0, 0, 0, 0.4);
  box-shadow: -1px 0px 5px -1px rgba(0, 0, 0, 0.4);
}

.float_center_style {
  width: 800px;
  max-width: 100%;
  height: 200px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 170px;
}

.UserAvatar {
  color: #fff;
  display: box;
}

.UserAvatar--dark {
  color: #fff;
}

.avatar_button {
  display: inline-block;
}

.weak {
  font-weight: lighter;
  font-size: 0.75rem;
}

/*WASTE INPUT*/
.divider_mato {
  /*border-bottom: 1px solid rgba(0, 0, 0, 0.125);*/
  border-bottom-width: 1px;
  margin-bottom: 0px;
}

.float_center_waste {
  width: 70%;
  max-width: 100%;
  margin: 0 auto;
}
.matomatic-cardbutton {
  margin-left: auto;
}

.matomatic-alignCenter {
  align-items: center;
}

.matomatic-alignEnd {
  align-items: flex-end;
}

.error {
  /*border: 1px solid red;*/
  color: red;
}

/*Override this*/
.app-header__logo .logo-src {
  height: 35px;
  width: 180px;
  background: url("~assets/utils/images/logo-matomatic-small2.png");
}

/*.matomatic-datepicker {
    border-top: none;
    border-right: none;
    border-bottom: none;
}

.matomatic-datepicker input {
    font-size: 1.75rem;
}*/

/* These are values needed for the green input increment thing that fades */
.matoValue-enter {
  opacity: 0.01;
  transition: opacity 0.5s ease-in;
}

.matoValue-enter.matoValue-enter-active {
  opacity: 1;
}

.matoValue-leave {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.matoValue-leave.matoValue-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.matoValue-appear {
  opacity: 0.01;
  transition: opacity 1s ease-in;
}

.matoValue-appear.matoValue-appear-active {
  opacity: 1;
}

.mato-gaugelabel {
  position: absolute;
  left: 32px;
  bottom: -4px;
}

/* New login page */
.app-logo-matomatic {
  height: 58px;
  width: 215px;
  background: url("~assets/utils/images/logo-matomatic.png");
  background-size: contain;
}

/* Table stuff */
.mato-table thead {
  line-height: normal;
  position: relative;
  font-weight: bold;
  border-right: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  color: #6c757d;
}
.mato-table thead,
.mato-table thead th:first-of-type {
  border-top: none;
  border-right: none;
}

.app-footer__inner {
  justify-content: center !important;
  display: flex !important;
}

/*.mato-table .rt-thead.-headerGroups .rt-th:hover, .ReactTable .rt-thead.-headerGroups .rt-td:hover {
    color: #6c757d;
}*/
